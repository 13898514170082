import React from "react";
import Button from "../Button";

interface Tab {
  label: string;
  value: string;
}

interface TabSwitcherProps<T extends string> {
  activeTab: T;
  tabs: Tab[];
  onTabChange: (value: T) => void;
}

const TabSwitcher = <T extends string>({
  activeTab,
  tabs,
  onTabChange,
}: TabSwitcherProps<T>) => {
  return (
    <div className="bg-white rounded flex justify-center mt-4 mb-4 space-x-2 flex-wrap">
      {tabs.map((tab, index) => (
        <Button
          key={tab.value}
          onClick={() => onTabChange(tab.value as T)}
          variant="link"
          className={`text-2xl py-2 px-4 transition duration-300 rounded-none hover:no-underline focus:outline-none 
          ${
            activeTab === tab.value
              ? "text-white font-bold hover:text-white bg-primary border-primary"
              : "text-primary-grey hover:text-primary hover:bg-primary-light"
          }`}
        >
          {tab.label}
        </Button>
      ))}
    </div>
  );
};

export default TabSwitcher;
