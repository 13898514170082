import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { setEquipmentSelection } from "../../../../../features/configuration/configurationSlice";
import { EquipmentOtherGroupedBySubclass } from "../../../../../types";
import EquipmentGroup from "../EquipmentGroup";
import { RootState } from "../../../../../app/store";
import { handrailGroupsData } from "./handrailGroupsData";

interface HandrailsProps {
  grouped: EquipmentOtherGroupedBySubclass;
}

const Handrails = ({ grouped }: HandrailsProps) => {
  const dispatch = useDispatch();

  const selectedHandrailId = useSelector(
    (state: RootState) => state.configuration.equipment.handrail.id
  );

  const handleHandrailChange = (
    id: number,
    name: string,
    imageName: string
  ) => {
    dispatch(
      setEquipmentSelection({
        category: "handrail",
        selection: { id, name, imageName },
      })
    );
  };

  return (
    <div className="mt-10">
      <hr className="border-t mb-4" />

      {handrailGroupsData.map(({ groupName, description, key }) => (
        <React.Fragment key={groupName}>
          <EquipmentGroup
            type="handrails"
            groupName={groupName}
            items={grouped[key]}
            selectedItem={selectedHandrailId}
            onRadioChange={handleHandrailChange}
            groupDescription={description}
          />

          <hr className="my-4 border-t" />
        </React.Fragment>
      ))}
    </div>
  );
};

export default Handrails;
