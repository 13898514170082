import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import Select from "../../../ui/Select";
import Loading from "../../../ui/Loading";
import Error from "../../../ui/Error";
import Handrails from "./Handrails";
import EquipmentGrid from "./EquipmentGrid";
import EquipmentRows from "./EquipmentRows";
import AccessControl from "./AccessControl";
import Hinges from "./Hinges";
import Cylinders from "./Cylinders";
import Chip from "../../../ui/Chip";
import {
  EquipmentSelections,
  setEquipmentSelection,
} from "../../../../features/configuration/configurationSlice";
import { RootState } from "../../../../app/store";
import { setActiveDropDownItem } from "../../../../features/sidebar/sidebarSlice";
import { useEquipmentCategories } from "../../../../hooks/useEquipmentCategories";
import { useEquipmentOthers } from "../../../../hooks/useEquipmentOthers";

// Import items
import { doorknobItems } from "./items/doorknobItems";
import { parapetProtectionItems } from "./items/parapetProtectionItems";
import { spyItems } from "./items/spyItems";
import { electromagneticReceiverItems } from "./items/electromagneticReceiverItems";
import { automaticClosingDeviceItems } from "./items/automaticClosingDeviceItems";

const Equipment = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    categories,
    isLoading: isLoadingCategories,
    isError: isErrorCategories,
  } = useEquipmentCategories();
  const {
    equipmentOthers,
    isLoading: isLoadingEquipment,
    isError: isErrorEquipment,
  } = useEquipmentOthers(true);

  const selectedDropDownItem = useSelector(
    (state: RootState) => state.sidebar.activeDropDownItem.equipment
  );

  const currentSelections = useSelector(
    (state: RootState) => state.configuration.equipment
  );

  const selectOptions = categories.map((category) => ({
    value: category.name,
    label: t(category.name),
  }));

  const handleSelectDropDownItem = (value: string) => {
    dispatch(setActiveDropDownItem({ field: "equipment", value }));
  };

  const handleItemSelect = (
    id: number | null,
    name: string | null,
    imageName: string | null,
    category: string
  ) => {
    dispatch(
      setEquipmentSelection({
        category: category as keyof EquipmentSelections,
        selection: { id, name, imageName },
      })
    );
  };

  const renderComponentBasedOnSelection = () => {
    const selectedCategory = categories.find(
      (category) => category.name === selectedDropDownItem
    );

    if (
      !selectedCategory ||
      !equipmentOthers ||
      // @ts-ignore
      !equipmentOthers[selectedCategory.name]
    ) {
      return null;
    }

    const selectedEquipments =
      // @ts-ignore
      equipmentOthers[selectedCategory.name].equipments;

    const groupedBySubcategory =
      // @ts-ignore
      equipmentOthers[selectedCategory.name]?.groupedBySubcategory;

    switch (selectedDropDownItem) {
      case "handrail":
        return <Handrails grouped={groupedBySubcategory} />;

      case "doorknobOutside":
      case "doorknobInside":
        return (
          <EquipmentRows
            items={doorknobItems}
            selectedItem={currentSelections.doorknobInside.id}
            handleItemSelect={(id, name, imageName) =>
              handleItemSelect(id, imageName, imageName, "doorknobInside")
            }
          />
        );

      case "cylinder":
        return (
          <Cylinders
            cylinders={selectedEquipments}
            selectedItem={currentSelections.cylinder.id}
            handleItemSelect={(id, name, imageName) =>
              handleItemSelect(id, imageName, imageName, "cylinder")
            }
          />
        );

      case "rosette":
        return (
          <EquipmentGrid
            items={selectedEquipments}
            selectedItem={currentSelections.rosette.id}
            handleItemSelect={(id, name, imageName) =>
              handleItemSelect(id, imageName, imageName, "rosette")
            }
          />
        );

      case "parapetProtection":
        return (
          <EquipmentRows
            items={parapetProtectionItems}
            selectedItem={currentSelections.parapetProtection.id}
            handleItemSelect={(id, name, imageName) =>
              handleItemSelect(id, name, imageName, "parapetProtection")
            }
            itemCanBeRemoved
          />
        );

      case "accessControl":
        return (
          <AccessControl
            handleItemSelect={(id, name, imageName) =>
              handleItemSelect(id, name, imageName, "accessControl")
            }
            grouped={groupedBySubcategory}
            selectedItem={currentSelections.accessControl.id}
          />
        );

      case "spy":
        return (
          <EquipmentRows
            items={spyItems}
            selectedItem={currentSelections.spy.id}
            handleItemSelect={(id, name, imageName) =>
              handleItemSelect(id, imageName, imageName, "spy")
            }
            itemCanBeRemoved
          />
        );

      case "hinges":
        return (
          <Hinges
            handleItemSelect={(id, name, imageName) =>
              handleItemSelect(id, name, imageName, "hinges")
            }
            grouped={groupedBySubcategory}
            selectedItem={currentSelections.hinges.id}
          />
        );

      case "electromagneticReceiver":
        return (
          <EquipmentRows
            items={electromagneticReceiverItems}
            selectedItem={currentSelections.electromagneticReceiver.id}
            handleItemSelect={(id, name, imageName) =>
              handleItemSelect(
                id,
                imageName,
                imageName,
                "electromagneticReceiver"
              )
            }
            itemCanBeRemoved
          />
        );

      case "automaticClosingDevice":
        return (
          <EquipmentRows
            items={automaticClosingDeviceItems}
            selectedItem={currentSelections.automaticClosingDevice.id}
            handleItemSelect={(id, name, imageName) =>
              handleItemSelect(
                id,
                imageName,
                imageName,
                "automaticClosingDevice"
              )
            }
            itemCanBeRemoved
          />
        );

      case "houseNumbers":
        return (
          <EquipmentGrid
            items={selectedEquipments}
            selectedItem={currentSelections.houseNumbers.id}
            handleItemSelect={(id, name, imageName) =>
              handleItemSelect(id, imageName, imageName, "houseNumbers")
            }
            itemCanBeRemoved
          />
        );

      default:
        return null;
    }
  };

  if (isLoadingCategories || isLoadingEquipment) return <Loading />;
  if (isErrorCategories || isErrorEquipment) return <Error />;

  return (
    <>
      <Chip classNames="mb-10">
        <h3 className=" text-2xl font-bold text-primary-green text-center">
          {t("choose-door-equipment")}
        </h3>
      </Chip>

      <Select
        options={selectOptions}
        value={selectedDropDownItem}
        onChange={(value: string) => handleSelectDropDownItem(value)}
      />

      {renderComponentBasedOnSelection()}
    </>
  );
};

export default Equipment;
