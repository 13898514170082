import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { EquipmentOther, EquipmentOtherTranslation } from "../../../../types";
import TextArea from "../../../ui/TextArea";
import Input from "../../../ui/Input";
import CoolCheckbox from "../../../ui/CoolCheckbox";
import FormFooter from "../FormFooter";
import InputImage from "../../../ui/InputImage";
import { useEquipmentCategories } from "../../../../hooks/useEquipmentCategories";
import Select from "../../../ui/Select";

interface AdminOtherEquipmentFormProps {
  equipment: EquipmentOther | null;
  onSubmit: (equipmentData: Partial<EquipmentOther>) => void;
  onCancel: () => void;
  isMutating: boolean;
}

const AdminOtherEquipmentForm: React.FC<AdminOtherEquipmentFormProps> = ({
  equipment,
  onSubmit,
  onCancel,
  isMutating,
}) => {
  const { i18n, t } = useTranslation();
  const supportedLngs = i18n.options?.supportedLngs;

  const { categories } = useEquipmentCategories();
  const languages = Array.isArray(supportedLngs)
    ? supportedLngs.filter((lng) => lng !== "cimode")
    : [];

  const createInitialTranslations = (): EquipmentOtherTranslation[] =>
    languages.map((language) => ({
      id: 0,
      equipment_id: 0,
      language,
      name: "",
      description: "",
    }));

  const mergeTranslations = (
    initial: EquipmentOtherTranslation[],
    existing: EquipmentOtherTranslation[]
  ): EquipmentOtherTranslation[] =>
    initial.map(
      (initialTranslation) =>
        existing.find((t) => t.language === initialTranslation.language) ||
        initialTranslation
    );

  const initializeFormData = (): Partial<EquipmentOther> => {
    if (equipment) {
      const translations = mergeTranslations(
        createInitialTranslations(),
        equipment.translations
      );

      return {
        ...equipment,
        translations,
        category_id: equipment.category_id || categories?.[0]?.id || undefined,
      };
    } else {
      return {
        code: "",
        thumbnail: "",
        price: "0",
        sort_order: null,
        is_shown: true,
        translations: createInitialTranslations(),
        category_id: categories?.[0]?.id || undefined,
      };
    }
  };

  const [formData, setFormData] = useState<Partial<EquipmentOther>>(
    initializeFormData()
  );

  useEffect(() => {
    setFormData(initializeFormData());
  }, []);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value, type } = e.target;
    const newValue = type === "number" ? value : value;
    setFormData((prevState) => ({ ...prevState, [name]: newValue }));
  };

  const handleFileChange = (base64Image: string | null) => {
    setFormData((prevState) => ({
      ...prevState,
      thumbnail: base64Image || "",
    }));
  };

  const handleTranslationChange = (
    language: string,
    field: keyof EquipmentOtherTranslation,
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { value } = e.target;

    const updatedTranslations = formData.translations?.map((translation) =>
      translation.language === language
        ? { ...translation, [field]: value }
        : translation
    );

    if (updatedTranslations) {
      setFormData((prevState) => ({
        ...prevState,
        translations: updatedTranslations,
      }));
    }
  };

  const handleCheckboxChange = (checked: boolean) => {
    setFormData((prevState) => ({ ...prevState, is_shown: checked }));
  };

  const handlePriceInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "-" || (e.currentTarget.value === "" && e.key === "0")) {
      e.preventDefault();
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(formData);
  };

  const handleCategoryChange = (selectedCategoryId: string) => {
    setFormData((prevState) => ({
      ...prevState,
      category_id: parseInt(selectedCategoryId, 10), // Convert to number if necessary
    }));
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <Input
        label="Kod"
        type="text"
        name="code"
        value={formData.code || ""}
        onChange={handleInputChange}
        required
      />

      <Select
        label="Kategorija"
        options={categories.map((category) => ({
          value: String(category.id),
          label: t(category.name),
        }))}
        value={String(formData.category_id) || "1"}
        onChange={handleCategoryChange}
        includeNoneOption={false}
      />

      {formData.translations?.map((translation) => (
        <div key={translation.language} className="space-y-2">
          <TextArea
            label={`Naziv (${translation.language.toUpperCase()})`}
            name={`name_${translation.language}`}
            value={translation.name || ""}
            onChange={(e) =>
              handleTranslationChange(translation.language, "name", e)
            }
            required
          />
          <TextArea
            label={`Opis (${translation.language.toUpperCase()})`}
            name={`description_${translation.language}`}
            value={translation.description || ""}
            onChange={(e) =>
              handleTranslationChange(translation.language, "description", e)
            }
          />
        </div>
      ))}

      <Input
        label="Cena"
        type="number"
        name="price"
        value={formData.price || "0"}
        onKeyDown={handlePriceInput}
        onChange={handleInputChange}
        min={0}
        step={0.01}
      />

      <div className="flex justify-between">
        <CoolCheckbox
          id="is_shown"
          checked={formData.is_shown || false}
          onChange={handleCheckboxChange}
          label="Prikazano"
        />

        <Input
          label="Redosled za sortiranje"
          type="number"
          name="sort_order"
          value={formData.sort_order || ""}
          onChange={handleInputChange}
        />
      </div>

      <InputImage
        name="thumbnail"
        initialImage={formData.thumbnail as string}
        onFileChange={handleFileChange}
      />

      <FormFooter onCancel={onCancel} isMutating={isMutating} />
    </form>
  );
};

export default AdminOtherEquipmentForm;
