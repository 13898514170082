import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import SidebarContent from "./SidebarContent";
import SidebarNavigation from "./SidebarNavigation";
import { NAVBAR_HEIGHT, SIDEBAR_HEADER_HEIGHT } from "../../constants";
import { useSidebar } from "../../hooks/useSidebar";

interface SidebarProps {
  setIsSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  hideSidebar: () => void;
  appRef: React.RefObject<HTMLDivElement>;
}

const Sidebar: React.FC<SidebarProps> = ({ appRef, setIsSidebarOpen, hideSidebar }) => {
  const { t } = useTranslation();
  const { activeItem, handleItemClick } = useSidebar();

  return (
    <aside className="flex flex-col flex-none transform w-full md:w-96 bg-primary-grey-light shadow-lg z-40">
      <div
        className="flex justify-between items-center p-4 bg-primary-light border-b border-primary-green"
        style={{ height: `${SIDEBAR_HEADER_HEIGHT - 1}px` }}
      >
        <h2 className="text-2xl text-black font-semibold">
          {t("configurator")}
        </h2>

        <button
          onClick={hideSidebar}
          className="text-black text-2xl focus:outline-none"
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </div>

      <nav
        className="text-white flex"
        style={{
          height: `calc(100vh - ${NAVBAR_HEIGHT + SIDEBAR_HEADER_HEIGHT}px)`,
        }}
      >
        <div className="overflow-y-auto no-scrollbar w-[20%]">
          <SidebarNavigation
            handleItemClick={handleItemClick}
            activeItem={activeItem}
          />
        </div>

        <div className="py-2 px-4 overflow-y-auto no-scrollbar w-[80%] bg-primary-light">
          <SidebarContent appRef={appRef} setIsSidebarOpen={setIsSidebarOpen} activeItem={activeItem} />
        </div>
      </nav>
    </aside>
  );
};

export default Sidebar;
