import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";

interface EquipmentSelection {
  id: number | null;
  name: string | null;
  imageName: string | null;
}

export interface EquipmentSelections {
  handrail: EquipmentSelection;
  doorknobInside: EquipmentSelection;
  rosette: EquipmentSelection;
  parapetProtection: EquipmentSelection;
  accessControl: EquipmentSelection;
  spy: EquipmentSelection;
  hinges: EquipmentSelection;
  electromagneticReceiver: EquipmentSelection;
  automaticClosingDevice: EquipmentSelection;
  houseNumbers: EquipmentSelection;
  cylinder: EquipmentSelection;
  lock: EquipmentSelection;
}

export interface ConfigurationState {
  totalPrice: number | null;
  interiorDoorShown: boolean;
  model: string;
  "model-id": number;
  type: string;
  "transom-glass": string;
  "transom-glass-id": number;
  "side-glass": string;
  "side-glass-id": number;
  "inner-glass": string | null;
  "inner-glass-id": number | null;
  "is-panel-color-default": boolean;
  "panel-color-name": string;
  "frame-color-name": string;
  "panel-color-id": number;
  "frame-color-id": number;
  system: string;
  "system-id": number;
  equipment: EquipmentSelections;

  //dimensions
  halfPanelWidth: number;
  width: number;
  height: number;
  leftSideWidth: number;
  rightSideWidth: number;
  upperGlassHeight: number;
  "panel-color": string | null;
  "frame-color": string | null;
  "left-side-glass-number": number;
  "right-side-glass-number": number;
  "DIN-opening-standard": string | null;

  // not needed data for API
  material: string | null;
  dimension: string | null;
  "sash-width": string | null;
  "side-glass-left": string | null;
  "side-glass-right": string | null;
}

const initialState: ConfigurationState = {
  totalPrice: null,
  interiorDoorShown: false,
  model: "1155",
  "model-id": 2,
  type: "single-leaf-door",
  "transom-glass": "sandblast",
  "transom-glass-id": 6,
  "side-glass": "sandblast",
  "side-glass-id": 6,
  "inner-glass": "default",
  "inner-glass-id": null,
  "is-panel-color-default": true,
  "panel-color-name": "7015 Matt",
  "frame-color-name": "7015 Matt",
  "panel-color-id": 1,
  "frame-color-id": 1,
  system: "PREMIUM AG 85",
  "system-id": 159,
  equipment: {
    handrail: { id: 241, name: "ES105 1000 mm", imageName: "es105ss-1000" },
    rosette: { id: 105, name: "Rozeta 1", imageName: "971-807-6500" },
    hinges: { id: 165, name: "ES 2407 3D White", imageName: "ES2400w" },
    doorknobInside: {
      id: 1,
      name: "995-525-6500 Kvaka 1",
      imageName: "995-525-6500",
    },
    parapetProtection: { id: null, name: null, imageName: null },
    accessControl: { id: null, name: null, imageName: null },
    spy: { id: null, name: null, imageName: null },
    electromagneticReceiver: {
      id: null,
      name: null,
      imageName: null,
    },
    automaticClosingDevice: { id: null, name: null, imageName: null },
    houseNumbers: { id: null, name: null, imageName: null },
    cylinder: { id: 132, name: "k55", imageName: null },
    lock: { id: 1, name: "2095568", imageName: null },
  },

  //dimensions
  width: 1050,
  height: 2100,
  halfPanelWidth: 1050,
  leftSideWidth: 500,
  rightSideWidth: 500,
  upperGlassHeight: 500,
  "panel-color": null,
  "frame-color": null,
  "left-side-glass-number": 1,
  "right-side-glass-number": 1,
  "DIN-opening-standard": "left-inside",

  // not needed data for API
  material: "aluminium",
  dimension: null,
  "sash-width": null,
  "side-glass-left": null,
  "side-glass-right": null,
};

export const configurationSlice = createSlice({
  name: "configuration",
  initialState,
  reducers: {
    setConfigurationField: <T extends keyof ConfigurationState>(
      state: Draft<ConfigurationState>,
      action: PayloadAction<{
        field: T;
        value: ConfigurationState[T];
      }>
    ) => {
      const { field, value } = action.payload;
      state[field] = value;
    },
    setEquipmentSelection: (
      state,
      action: PayloadAction<{
        category: keyof EquipmentSelections;
        selection: EquipmentSelection;
      }>
    ) => {
      const { category, selection } = action.payload;
      state.equipment[category] = selection;
    },
    setTotalPrice: (state, action: PayloadAction<number | null>) => {
      state.totalPrice = action.payload;
    },
  },
});

export const { setConfigurationField, setEquipmentSelection, setTotalPrice } =
  configurationSlice.actions;

export default configurationSlice.reducer;
