import React, {
  HTMLInputTypeAttribute,
  ChangeEventHandler,
  KeyboardEventHandler,
} from "react";
import Chip from "../Chip";

interface InputProps {
  label?: string;
  type: HTMLInputTypeAttribute;
  name: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  placeholder?: string;
  required?: boolean;
  value?: string | number;
  error?: string;
  min?: number;
  max?: number;
  step?: number;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
}

const Input: React.FC<InputProps> = ({
  label,
  error,
  name,
  required,
  placeholder,
  ...rest
}) => {
  return (
    <div>
      {label && (
        <label htmlFor={name} className="block mb-2 text-sm font-medium w-fit">
          <Chip>
            {label} {required && <span className="text-danger">*</span>}
          </Chip>
        </label>
      )}

      <input
        id={name}
        name={name}
        placeholder={required && placeholder ? `${placeholder} *` : placeholder}
        className={`bg-primary-ultra-light border-primary text-primary text-sm rounded-lg focus:ring-primary-dark focus:border-primary-dark block w-full p-2.5 ${
          error ? "border-danger" : ""
        }`}
        {...rest}
      />
      {error && <p className="text-danger text-xs mt-1">{error}</p>}
    </div>
  );
};

export default Input;
