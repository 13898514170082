import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

interface LoadingProps {
  classNames?: string;
  variant?: "primary" | "primary-light";
  size?: "xs" | "sm" | "md" | "lg"; // Optional prop for sizing
}

const Loading = ({
  classNames,
  variant = "primary",
  size = "md",
}: LoadingProps) => {
  const colorVariants = {
    primary: "text-primary",
    "primary-light": "text-primary-light",
  };

  const sizeVariants = {
    xs: "text-lg",
    sm: "text-2xl",
    md: "text-4xl",
    lg: "text-6xl",
  };

  return (
    <div className={`flex justify-center items-center h-full ${classNames}`}>
      <FontAwesomeIcon
        icon={faSpinner}
        className={`${colorVariants[variant]} ${sizeVariants[size]} animate-spin rounded-full`}
        aria-hidden="true"
      />
    </div>
  );
};

export default Loading;
