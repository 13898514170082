import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import Dropdown from "../ui/Dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import Button from "../ui/Button";

const AdditionalNavItems = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const dropdownItems = [
    {
      key: "homePage",
      label: t("homePage"),
      path: "/admin",
      action: () => navigate("/admin"),
    },
    {
      key: "users",
      label: t("users"),
      path: "/admin/users",
      action: () => navigate("/admin/users"),
    },
  ];

  const isActive = (path: string) => location.pathname === path;

  return (
    <>
      {/* Desktop View */}
      <div className="hidden sm:flex">
        {dropdownItems.map((item) => (
          <Button
            variant="link"
            key={item.key}
            onClick={item.action}
            className={`no-underline ${
              isActive(item.path)
                ? "text-primary-green font-semibold"
                : "text-black"
            }`}
          >
            {item.label}
          </Button>
        ))}
      </div>

      {/* Mobile View */}
      <div className="block sm:hidden">
        <Dropdown
          trigger={
            <FontAwesomeIcon
              icon={faBars}
              size="2x"
              className="cursor-pointer text-black hover:text-primary-green"
            />
          }
          items={dropdownItems.map((item) => ({
            ...item,
            className: isActive(item.path) ? "text-primary-green" : "",
          }))}
          className="right-0 w-48 bg-translucent text-white shadow-lg rounded-xl"
        />
      </div>
    </>
  );
};

export default AdditionalNavItems;
