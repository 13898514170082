import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Loading from "../../../ui/Loading";
import Error from "../../../ui/Error";
import Select from "../../../ui/Select";
import { useDoors } from "../../../../hooks/useDoors";
import { Door } from "../../../../types";
import { setConfigurationField } from "../../../../features/configuration/configurationSlice";
import { RootState } from "../../../../app/store";
import Selectable from "../../../ui/Selectable";
import { setActiveDropDownItem } from "../../../../features/sidebar/sidebarSlice";
import Chip from "../../../ui/Chip";

const DoorModel = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const selectedDropDownItem = useSelector(
    (state: RootState) => state.sidebar.activeDropDownItem.model
  );

  const selectedModel = useSelector(
    (state: RootState) => state.configuration.model
  );

  const isPanelColorDefault = useSelector(
    (state: RootState) => state.configuration["is-panel-color-default"]
  );

  const { doors, isLoading, isError } = useDoors(selectedDropDownItem);

  const selectOptions = [
    { value: "all", label: t("all-models") },
    { value: "with-glass", label: t("models-with-glass") },
    { value: "without-glass", label: t("models-without-glass") },
  ];

  const handleSelectDoorModel = (doorModel: Door) => {
    if (doorModel.has_glass) {
      dispatch(
        setConfigurationField({
          field: "inner-glass",
          value: doorModel.decorative_glass_name ? "default" : "sandblast",
        })
      );
    } else {
      dispatch(setConfigurationField({ field: "inner-glass", value: null }));
    }

    dispatch(
      setConfigurationField({ field: "model", value: doorModel.model_code })
    );

    dispatch(setConfigurationField({ field: "model-id", value: doorModel.id }));

    if (isPanelColorDefault && doorModel.color?.color_code) {
      dispatch(
        setConfigurationField({
          field: "panel-color-name",
          value: doorModel.color.color_code,
        })
      );

      dispatch(
        setConfigurationField({
          field: "panel-color-id",
          value: doorModel.color.id,
        })
      );

      dispatch(
        setConfigurationField({
          field: "frame-color-name",
          value: doorModel.color.color_code,
        })
      );

      dispatch(
        setConfigurationField({
          field: "frame-color-id",
          value: doorModel.color.id,
        })
      );
    }
  };

  const handleSelectDropDownItem = (value: string) => {
    dispatch(setActiveDropDownItem({ field: "model", value }));
  };

  if (isLoading) return <Loading />;
  if (isError) return <Error message={t("error-occurred")} />;

  return (
    <>
      <Chip classNames="mb-10">
        <h3 className=" text-2xl font-bold text-primary-green text-center">
          {t("choose-door-model")}
        </h3>
      </Chip>

      <Select
        options={selectOptions}
        value={selectedDropDownItem}
        onChange={(value) => handleSelectDropDownItem(value)}
        classNames="mb-4"
      />

      <div className="grid grid-cols-1 md:grid-cols-3 gap-2 ">
        {doors?.map((doorModel: Door) => {
          const isSelected = selectedModel === doorModel.model_code;
          const fullModelName =
            doorModel.dmodels && doorModel.dmodels.length
              ? `${doorModel.model_code}-${doorModel.dmodels
                  .map((d) => d.suffix)
                  .join("-")}`
              : doorModel.model_code;

          return (
            <Selectable
              key={doorModel.id}
              isSelected={isSelected}
              onClick={() => handleSelectDoorModel(doorModel)}
            >
              <img
                className="h-36"
                src={`${process.env.REACT_APP_API_URL}/${doorModel.thumbnail}`}
                alt={`Door Model ${fullModelName}`}
              />

              <span className="mt-2 text-sm">{fullModelName}</span>
            </Selectable>
          );
        })}
      </div>
    </>
  );
};

export default DoorModel;
