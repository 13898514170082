import EquipmentGroup from "../EquipmentGroup";
import { EquipmentOther } from "../../../../../types";
import RemoveItem from "../../../../ui/RemoveItem";

interface AccessControlProps {
  handleItemSelect: (
    id: number | null,
    name: string | null,
    imageName: string | null
  ) => void;
  selectedItem: number | null;
  grouped: {
    [key: string]: EquipmentOther[];
  };
}

const AccessControl = ({
  handleItemSelect,
  selectedItem,
  grouped,
}: AccessControlProps) => {
  return (
    <>
      <div className="h-8">
        <RemoveItem
          isShowed={selectedItem !== null}
          onClick={() => handleItemSelect(null, null, null)}
        />
      </div>

      <hr className="mb-4 border-t" />

      <EquipmentGroup
        type="access-control"
        groupName="es-2000"
        items={grouped.es2000}
        selectedItem={selectedItem}
        onRadioChange={handleItemSelect}
        groupDescription="Finger scanner ekey INTEGRA 2.1"
      />

      <hr className="my-4 border-t" />

      <EquipmentGroup
        type="access-control"
        groupName="es-2005"
        items={grouped.es2005}
        selectedItem={selectedItem}
        onRadioChange={handleItemSelect}
        groupDescription="Finger scanner ekey INTEGRA 2.1 Bluetooth"
      />

      <hr className="my-4 border-t" />

      <EquipmentGroup
        type="access-control"
        groupName="es-2010"
        items={grouped.es2010}
        selectedItem={selectedItem}
        onRadioChange={handleItemSelect}
        groupDescription="Finger scanner ekey ARTE"
      />

      <hr className="my-4 border-t" />

      <EquipmentGroup
        type="access-control"
        groupName="es-2016"
        items={grouped.es2016}
        selectedItem={selectedItem}
        onRadioChange={handleItemSelect}
        groupDescription="Bluetooth Finger scanner ekey ARTE"
      />
    </>
  );
};

export default AccessControl;
