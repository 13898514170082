import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { checkDoorFeatures, todaysDate } from "../utils";
import { RootState } from "../app/store";
import { usePrintQueryGlass } from "./usePrintQueryGlass";
import useConstruction from "./useConstruction";

interface SectionData {
  [key: string]: string | null;
}

interface Section {
  title: string;
  data: SectionData;
}

interface Sections {
  [sectionKey: string]: Section;
}

const usePrepareConfiguration = () => {
  const { t, i18n } = useTranslation();
  const configuration = useSelector((state: RootState) => state.configuration);
  const glassSectionData = usePrintQueryGlass();

  const { hasTransom, hasDoubleDoor, hasLeftGlass, hasRightGlass } =
    checkDoorFeatures(configuration["type"]);

  const doorConfiguration = useSelector(
    (state: RootState) => state.configuration
  );

  const { sumOfAllWidths, sumOfAllHeights } =
    useConstruction(doorConfiguration);

  const translateValue = (value: string, translateOnSerbian: boolean) => {
    const serbianTranslations = i18n.getDataByLanguage("sr")?.translation;

    return translateOnSerbian &&
      serbianTranslations &&
      serbianTranslations[value]
      ? serbianTranslations[value]
      : t(value);
  };

  const translateDataKeys = (
    data: SectionData,
    translateOnSerbian: boolean
  ): SectionData => {
    const translatedData: SectionData = {};

    Object.keys(data).forEach((key) => {
      const translatedKey = translateValue(key, translateOnSerbian);
      translatedData[translatedKey] = data[key];
    });

    return translatedData;
  };

  const constructSections = (translateOnSerbian: boolean) => {
    const sections: Sections = {
      yourConfiguration: {
        title: translateValue("your-configuration", translateOnSerbian),
        data: {
          "reference-number": "00000000", // TODO: add dynamic reference number
          date: todaysDate(),
          model: configuration.model,
        },
      },
      construction: {
        title: translateValue("construction", translateOnSerbian),
        data: {
          type: translateValue(configuration.type, translateOnSerbian),
          material: translateValue(
            configuration.material ?? "",
            translateOnSerbian
          ),
          "system-profile": configuration.system,
          "external-measure": `${sumOfAllWidths} x ${sumOfAllHeights} mm`,
          "door-dimensions": `${configuration["width"]} x ${configuration["height"]} mm`,
          "half-panel-width": hasDoubleDoor
            ? `${configuration["halfPanelWidth"]} mm`
            : null,
          [`${translateValue(
            "side-glass-left",
            translateOnSerbian
          )} (${translateValue("width", translateOnSerbian)})`]: hasLeftGlass
            ? `${configuration["leftSideWidth"]} mm`
            : null,
          [`${translateValue("side-glass-right", translateOnSerbian)} (${t(
            "width"
          )})`]: hasRightGlass ? `${configuration["rightSideWidth"]} mm` : null,
          "upper-glass-height": hasTransom
            ? `${configuration["upperGlassHeight"]} mm`
            : null,
          "DIN-standard":
            translateValue(
              configuration["DIN-opening-standard"]?.split("-")[0] || "",
              translateOnSerbian
            ) +
            "-" +
            translateValue(
              configuration["DIN-opening-standard"]?.split("-")[1] || "",
              translateOnSerbian
            ),
          "left-side-glass-number": hasLeftGlass
            ? `${configuration["left-side-glass-number"]} x`
            : null,
          "right-side-glass-number": hasRightGlass
            ? `${configuration["right-side-glass-number"]} x`
            : null,
        },
      },
      color: {
        title: translateValue("color", translateOnSerbian),
        data: {
          "panel-color": configuration["panel-color-name"],
          "frame-color": configuration["frame-color-name"],
        },
      },
      glass: {
        title: translateValue("glass", translateOnSerbian),
        data: glassSectionData,
      },
      equipment: {
        title: translateValue("equipment", translateOnSerbian),
        data: {
          handrail: configuration.equipment.handrail.name,
          "doorknob-inside": configuration.equipment.doorknobInside.name,
          rosette: configuration.equipment.rosette.name,
          "parapet-protection": configuration.equipment.parapetProtection.name,
          cylinder: configuration.equipment.cylinder.name,
          spy: configuration.equipment.spy.name,
          "access-control": configuration.equipment.accessControl.name,
          hinges: configuration.equipment.hinges.name,
          lock: configuration.equipment.lock.name,
          "automatic-closing-device":
            configuration.equipment.automaticClosingDevice.name,
          "electromagnetic-receiver":
            configuration.equipment.electromagneticReceiver.name,
          "house-numbers": configuration.equipment.houseNumbers.name,
        },
      },
    };

    Object.keys(sections).forEach((sectionKey) => {
      sections[sectionKey].data = translateDataKeys(
        sections[sectionKey].data,
        translateOnSerbian
      );
    });

    return sections;
  };

  const sectionsSerbian = constructSections(true);
  const sectionsUserLang = constructSections(false);

  return { sectionsSerbian, sectionsUserLang };
};

export default usePrepareConfiguration;
