import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import { faBars, faRotate } from "@fortawesome/free-solid-svg-icons";
import "react-toastify/dist/ReactToastify.css";

import { AppDispatch, RootState } from "./app/store";
import { fetchUserData } from "./features/user/userSlice";
import { setConfigurationField } from "./features/configuration/configurationSlice";
import { selectActiveItem } from "./features/sidebar/sidebarSlice";
import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import Main from "./components/Main";
import Button from "./components/ui/Button";
import { useLocation } from "react-router-dom";

const App: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const redirected = location.state?.redirected;

  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const activeItem = useSelector(selectActiveItem);
  const colorImageUrl = useSelector(
    (state: RootState) => state.background.colorImageUrl
  );
  const houseImageUrl = useSelector(
    (state: RootState) => state.background.houseImageUrl
  );
  const interiorDoorShown = useSelector(
    (state: RootState) => state.configuration.interiorDoorShown
  );

  const buttonClasses = "mt-4 h-min w-min ml-4";

  const appRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!redirected) {
      dispatch(fetchUserData());
    }
  }, [dispatch, redirected]);

  const SidebarToggleButton = () => (
    <Button
      className={buttonClasses}
      onClick={() => setIsSidebarOpen(true)}
      icon={faBars}
    >
      {t("menu")}
    </Button>
  );

  const DoorSideToggleButton = () => {
    return (
      <Button
        icon={faRotate}
        className={`${buttonClasses} ${
          isSidebarOpen ? "hidden sm:block" : "block"
        }`}
        onClick={() =>
          dispatch(
            setConfigurationField({
              field: "interiorDoorShown",
              value: !interiorDoorShown,
            })
          )
        }
      >
        <p className="w-max">
          {t(interiorDoorShown ? "door-outer-view" : "door-inner-view")}
        </p>
      </Button>
    );
  };

  return (
    <>
      <ToastContainer position="bottom-right" autoClose={5000} />

      <div
        ref={appRef}
        id="app-content"
        className={`flex flex-col h-screen ${
          houseImageUrl ? "bg-contain bg-no-repeat bg-center " : ""
        }`}
        style={{
          backgroundImage: `url(${
            activeItem === "insert-the-door" ? houseImageUrl : colorImageUrl
          })`,
        }}
      >
        <Navbar />

        <div className="flex-grow flex flex-col md:flex-row">
          {isSidebarOpen ? (
            <Sidebar
              appRef={appRef}
              setIsSidebarOpen={setIsSidebarOpen}
              hideSidebar={() => setIsSidebarOpen(false)}
            />
          ) : (
            <SidebarToggleButton />
          )}

          <DoorSideToggleButton />

          <Main
            isSidebarOpen={isSidebarOpen}
            hideSidebar={() => setIsSidebarOpen(false)}
          />
        </div>
      </div>
    </>
  );
};

export default App;
