import React, { useState } from "react";
import Modal from "../../../ui/Modal";
import AdminOtherEquipmentForm from "./AdminOtherEquipmentForm";
import AdminDeleteConfirmationModal from "../AdminTableItems/AdminDeleteConfirmationModal";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Button from "../../../ui/Button";
import { EquipmentOther } from "../../../../types";
import Table, { TableColumn } from "../../../ui/Table";
import Error from "../../../ui/Error";
import Loading from "../../../ui/Loading";
import Chip from "../../../ui/Chip";
import { toast } from "react-toastify";
import { useEquipmentOthers } from "../../../../hooks/useEquipmentOthers";
import StatusIndicator from "../AdminTableItems/StatusIndicator";
import ImagePreview from "../AdminTableItems/ImagePreview";
import TabSwitcher from "../../../ui/TabSwitcher";
import { useTranslation } from "react-i18next";
import ActionButtons from "../../../ui/Table/ActionButtons";

const AdminOtherEquipment: React.FC = () => {
  const { t } = useTranslation();
  const [editingEquipment, setEditingEquipment] =
    useState<EquipmentOther | null>(null);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [equipmentToDelete, setEquipmentToDelete] =
    useState<EquipmentOther | null>(null);
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);

  const {
    isLoading,
    isError,
    equipmentOthers,
    deleteEquipmentOther,
    addOrUpdateEquipmentOther,
  } = useEquipmentOthers();

  const [activeCategory, setActiveCategory] = useState<string>("handrail");

  const categories = equipmentOthers
    ? Object.keys(equipmentOthers).map((category) => ({
        label: t(category),
        value: category,
      }))
    : [];

  const filteredEquipments: EquipmentOther[] =
    equipmentOthers && activeCategory
      ? //@ts-ignore
        equipmentOthers[activeCategory]?.equipments || []
      : [];

  const columns: TableColumn[] = [
    { header: "ID", accessor: "id", sortable: true, filterable: false },
    {
      header: "Naziv",
      accessor: "sr_name",
      sortable: true,
      filterable: true,
    },
    {
      header: "Cena",
      accessor: "price",
      render: (row: EquipmentOther) => parseFloat(row.price).toFixed(2),
      sortable: true,
      filterable: true,
    },
    {
      header: "Redosled",
      accessor: "sort_order",
      render: (row: EquipmentOther) =>
        row.sort_order ? `#${row.sort_order}` : "-",
      sortable: true,
    },
    {
      header: "Slika",
      accessor: "thumbnail",
      render: (row: EquipmentOther) => (
        <ImagePreview thumbnail={row.thumbnail as string} name={row.code} />
      ),
    },
    {
      header: "Status",
      accessor: "is_shown",
      render: (row: EquipmentOther) => (
        <StatusIndicator isShown={row.is_shown} />
      ),
      sortable: true,
    },
  ];

  const handleAddOrUpdateEquipment = async (
    equipmentData: Partial<EquipmentOther>
  ) => {
    try {
      await addOrUpdateEquipmentOther(
        editingEquipment ? editingEquipment.id : null,
        equipmentData
      );
      toast.success(
        editingEquipment
          ? "Stavka uspešno ažurirana"
          : "Stavka uspešno kreirana"
      );
    } catch (error) {
      toast.error("Greška prilikom snimanja stavke");
    } finally {
      setIsFormModalOpen(false);
      setEditingEquipment(null);
    }
  };

  const handleCancel = () => {
    setIsFormModalOpen(false);
    setEditingEquipment(null);
  };

  const handleDeleteClick = (equipment: EquipmentOther) => {
    setEquipmentToDelete(equipment);
    setIsConfirmModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (equipmentToDelete) {
      try {
        await deleteEquipmentOther(equipmentToDelete.id);
        toast.success("Stavka je uspešno obrisana");
      } catch (error) {
        toast.error("Stavka nije obrisana. Došlo je do greške.");
      } finally {
        setEquipmentToDelete(null);
        setIsConfirmModalOpen(false);
      }
    }
  };

  const handleEditClick = (equipment: EquipmentOther) => {
    setEditingEquipment(equipment);
    setIsFormModalOpen(true);
  };

  const handleAddNewClick = () => {
    setEditingEquipment(null);
    setIsFormModalOpen(true);
  };

  const handleTabChange = (category: string) => {
    setActiveCategory(category);
  };

  return (
    <div className="flex flex-col gap-3 p-4">
      <Chip classNames="w-fit">
        <h3 className="text-2xl font-bold">Oprema</h3>
      </Chip>

      <TabSwitcher
        activeTab={activeCategory}
        tabs={categories}
        onTabChange={handleTabChange}
      />

      <div className="flex justify-center">
        <Button
          icon={faPlus}
          onClick={handleAddNewClick}
          className="w-fit"
          disabled={isLoading}
        >
          Dodaj novu opremu
        </Button>
      </div>

      {isLoading && <Loading />}

      {isError && <Error message="Greška prilikom učitavanja" />}

      {filteredEquipments.length > 0 && (
        <Table
          striped
          pagination
          columns={columns}
          data={filteredEquipments}
          actionButtons={(row: EquipmentOther) => (
            <ActionButtons
              onEdit={() => handleEditClick(row)}
              onDelete={() => handleDeleteClick(row)}
            />
          )}
        />
      )}

      {isFormModalOpen && (
        <Modal
          isOpen={isFormModalOpen}
          onClose={handleCancel}
          title={editingEquipment ? "Ažuriraj stavku" : "Dodaj novu stavku"}
        >
          <AdminOtherEquipmentForm
            equipment={editingEquipment}
            onSubmit={handleAddOrUpdateEquipment}
            onCancel={handleCancel}
            isMutating={isLoading}
          />
        </Modal>
      )}

      <AdminDeleteConfirmationModal
        isMutating={isLoading}
        setIsConfirmModalOpen={setIsConfirmModalOpen}
        handleConfirmDelete={handleConfirmDelete}
        isConfirmModalOpen={isConfirmModalOpen}
        text="Da li ste sigurni da želite da obrišete ovu stavku?"
      />
    </div>
  );
};

export default AdminOtherEquipment;
