import React from "react";
import { useSelector } from "react-redux";

import algreenLogoGreenWhiteBackground from "../../../../../assets/images/algreen-logo-green-white-background.png";
import { selectUserData } from "../../../../../features/user/userSlice";

const Header = () => {
  const userData = useSelector(selectUserData);

  const logoUrl = userData?.logo
    ? `${process.env.REACT_APP_API_URL}/storage/${userData.logo}`
    : algreenLogoGreenWhiteBackground;

  return (
    <div>
      <div className="flex justify-between items-center mb-1 pb-3">
        <img
          src={logoUrl}
          className="w-auto max-h-12 object-contain object-left"
          alt="Logo"
        />

        <div className="text-sm text-right">
          <p>
            <b>ALGREEN DOO</b>
          </p>
          <p>Bulevar Svetog Cara Konstantina 168</p>
          <p>Niš</p>
          <p>T: 018/450-054; 540-005; 063/581-217</p>
          <p>E: email@mail.com</p>
        </div>
      </div>

      <hr className="border-t border-gray-500 mb-6" />
    </div>
  );
};

export default Header;
