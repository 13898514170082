import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Door } from "../../../types";
import Input from "../../ui/Input";
import InputImage from "../../ui/InputImage";
import CoolCheckbox from "../../ui/CoolCheckbox";
import FormFooter from "../AdminComponents/FormFooter";
import Select from "../../ui/Select";

interface AdminDoorFormProps {
  door: Door | null;
  onSubmit: (doorData: Partial<Door>) => void;
  onCancel: () => void;
  isMutating: boolean;
}

const AdminDoorForm: React.FC<AdminDoorFormProps> = ({
  door,
  onSubmit,
  onCancel,
  isMutating,
}) => {
  const { t } = useTranslation();

  const initializeFormData = (): Partial<Door> => {
    if (door) {
      return { ...door };
    } else {
      return {
        model_code: "",
        thumbnail: "",
        color_id: 0,
        decorative_glass_name: "",
        side_glass_code: "",
        second_panel_color_id: null,
        has_glass: false,
        price: "0",
        sort_order: null,
        is_shown: true,
      };
    }
  };

  const [formData, setFormData] = useState<Partial<Door>>(initializeFormData());

  useEffect(() => {
    setFormData(initializeFormData());
    // eslint-disable-next-line
  }, [door]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value, type } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "number" ? parseFloat(value) : value,
    }));
  };

  const handleCheckboxChange = (checked: boolean) => {
    setFormData((prevState) => ({ ...prevState, has_glass: checked }));
  };

  const handleFileChange = (base64Image: string | null) => {
    setFormData((prevState) => ({
      ...prevState,
      thumbnail: base64Image || "",
    }));
  };

  const handlePriceInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "-" || (e.currentTarget.value === "" && e.key === "0")) {
      e.preventDefault();
    }
  };

  const handleSelectChange = (value: number | null, fieldName: keyof Door) => {
    setFormData((prevState) => ({ ...prevState, [fieldName]: value }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <Input
        label={t("model-code")}
        type="text"
        name="model_code"
        value={formData.model_code || ""}
        onChange={handleInputChange}
        required
      />

      <InputImage
        name="thumbnail"
        initialImage={formData.thumbnail as string}
        onFileChange={handleFileChange}
      />

      <Select
        label={t("color")}
        options={[
          { value: "1", label: "Red" },
          { value: "2", label: "Blue" },
        ]}
        value={formData.color_id?.toString() || "0"}
        onChange={(value) => handleSelectChange(+value, "color_id")}
      />

      <Input
        label={t("Decorative Glass Name")}
        type="text"
        name="decorative_glass_name"
        value={formData.decorative_glass_name || ""}
        onChange={handleInputChange}
      />

      <Input
        label={t("Side Glass Code")}
        type="text"
        name="side_glass_code"
        value={formData.side_glass_code || ""}
        onChange={handleInputChange}
      />

      <Select
        label={t("Second Panel Color")}
        options={[
          { value: "1", label: "Green" },
          { value: "2", label: "Yellow" },
        ]}
        value={formData.second_panel_color_id?.toString() || "0"}
        onChange={(value) =>
          handleSelectChange(+value, "second_panel_color_id")
        }
      />

      <div className="flex justify-between">
        <Input
          label={t("price")}
          type="number"
          name="price"
          value={formData.price || "0"}
          onKeyDown={handlePriceInput}
          onChange={handleInputChange}
          min={0}
          step={0.01}
        />

        <Input
          label="Redosled za sortiranje"
          type="number"
          name="sort_order"
          value={formData.sort_order || ""}
          onChange={handleInputChange}
        />
      </div>

      <CoolCheckbox
        id="has_glass"
        checked={formData.has_glass || false}
        onChange={handleCheckboxChange}
        label="Ima staklo"
      />

      <CoolCheckbox
        id="is_shown"
        checked={formData.is_shown || false}
        onChange={(checked) =>
          setFormData((prev) => ({ ...prev, is_shown: checked }))
        }
        label="Prikazano"
      />

      <FormFooter onCancel={onCancel} isMutating={isMutating} />
    </form>
  );
};

export default AdminDoorForm;
